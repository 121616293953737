import { createSlice } from '@reduxjs/toolkit';

const name = `ads`;

const slice = createSlice({
  name,
  initialState: {
    data: [],
    status: `idle`,
    error: null,
  },
  reducers: {
    setAds(state,action){
      state.data = action.payload;
    },
    setAdsSlice(state,action){
      return action.payload;
    },
    updateAd(state,action){
      const { id,...updates } = action.payload;
      state.data.forEach(ad => {
        if(ad.id === id)
          Object.keys(updates).forEach(key => {
            ad[key] = updates[key];
          });
      });

    },
  },
});

export default slice.reducer;
export const { setAds,setAdsSlice,updateAd } = slice.actions;
export const selectAds = state => state[name].data;
export const selectAdsStatus = state => state[name].status;