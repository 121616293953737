import moment from 'moment';

export const formatCurrency = value => (Number(value) || 0)
  .toLocaleString(`en-US`,{ style: `currency`,currency: `USD`,minimumFractionDigits: 2,maximumFractionDigits: 2 });
export const formatPercentage = value => `${(value * 100).toFixed(2)}%`;
export const formatThousandsPercentage = value => `${(value * 100).toFixed(3)}%`;

export const formatDate = value => value ? moment(value.slice(0,10)).format(`MMM DD, YYYY`) : null ;
export const formatWeek = value => value ? moment(value.slice(0,10)).format(`WW, YYYY`) : null ;
export const formatMonth = value => value ? moment(value.slice(0,10)).format(`MMM YYYY`) : null ;

export const formatCommas = value => Number(value).toLocaleString();
export const formatPhone = value => {
  const group1 = value.slice(0,3);
  const group2 = value.slice(3,6);
  const group3 = value.slice(6);
  return [group1,group2,group3].filter(Boolean).join(`-`);
};

export const format = (value,type) => {
  if(!type) return value;
  return {
    commas: formatCommas,
    currency: formatCurrency,
    percentage: formatPercentage,
    number: formatCommas,
    date: formatDate,
    string: string => string,
  }[type](value);
};