import { createSelector,createSlice } from '@reduxjs/toolkit';
import { columns } from '../columns';
import { calcPublisherMetrics } from '../utility/calcMetrics';
import { formatMetrics } from '../utility/formatMetrics';
import { sorter } from '../utility/sorter';
import { selectEndpoints } from './endpoints.slice';
import {
  selectDim1,selectDim2,selectExclCols,selectPage,selectPerPage,selectStartDate,selectEndDate,
} from './url.slice';

const name = `statsByDim`;
const { publisherColumns } = columns ;


const initialState = {
  data: [],
  status: `idle`,
  error: null,
};
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setStatsByDim(state,action){
      state.data = action.payload;
    },
    resetStatsByDimSlice(state,action){
      state = initialState;
    },
  },
});

export default slice.reducer;
export const { setStatsByDim,resetStatsByDimSlice } = slice.actions;
export const selectStatsByDim = state => state.statsByDim.data;
export const selectStatsByDimStatus = state => state.statsByDim.status;
export const selectStatsByDimSlice = state => state.statsByDim;

export const selectMetricsByDim = createSelector(
  [selectStatsByDim],
  data => {
    data = data.map(d => {
      d = { ...d };
      if (d.widget_name) d.widget_id = `${d.widget_name} - ${d.widget_id}`;
      return d;
    });
    data = calcPublisherMetrics(data);
    return data;
  },
);

export const selectFormattedMetricsByDim = createSelector(
  [selectMetricsByDim],
  data => {
    data = formatMetrics(data);
    return data;
  },
);

export const selectSortedMetricsByDim = createSelector(
  [selectFormattedMetricsByDim,
    state => state.url.dim1,
    state => state.url.dim2,
    state => state.url.sortBy,
    state => state.url.sortAsc,
  ],
  (data,dim1,dim2,sortBy,sortAsc) => {
    //TODO: get rid of this
    if(sortBy === `impressions`) sortBy = `client_impressions`;
    if (dim1){
      const column = publisherColumns.find(c => c.id === dim1);
      if(column) data = sorter(data,dim1,sortAsc,column.sortMethod);
    }
    if (dim2){
      const column = publisherColumns.find(c => c.id === dim2);
      if(column) data = sorter(data,dim1,sortAsc,column.sortMethod);
    }
    const sortColumn = publisherColumns.find(c => c.id === sortBy);
    if(sortColumn)
      data = sorter(data,sortBy,sortAsc,sortColumn.sortMethod);
    return [...data];
  },
);

export const selectTableData = createSelector(
  [selectSortedMetricsByDim],
  data => data.map(d => {
    d = { ...d };
    Object.keys(d).forEach(key => {
      if (!columns.publisherColumns.map(c => c.id).includes(key)) delete d[key];
    });
    return d;
  }),
);

export const selectPageData = createSelector(
  [selectTableData,selectPage,selectPerPage],
  (data,page,perPage) => {
    return data ? data.slice(page * perPage,(Number(page) + 1) * perPage) : [];
  },
);
export const selectTotalsData = createSelector(
  [selectTableData],
  data => {
    let totals = data.reduce((acc,cur) => {
      cur = { ...cur };
      Object.keys(cur).forEach(key => {
        if (!(key in acc)) acc[key] = 0;
        if (String(cur[key]).includes(`%`))
          cur[key] = Number(String(cur[key]).replace(/%/g,``)) / 100;
        acc[key] += Number(String(cur[key])
          .replace(/\$/g,``)
          .replace(/,/g,``),
        );
      });
      return acc;
    },{});

    // eslint-disable-next-line
    totals = calcPublisherMetrics([totals])[0];
    // eslint-disable-next-line
    totals = formatMetrics([totals])[0];
    return totals;
  },
);

export const selectAvailableColumns = createSelector(
  [selectTableData],
  data => {
    if (!data) return [];
    if (!data.length) return [];
    return Object.keys(data[0]);
  },
);

export const viewablePublisherColumns = createSelector(
  [selectExclCols,selectDim1,selectDim2],
  (exclCols,dim1,dim2) => {
    if(dim1 === `widget`) dim1 = `widget_id`;
    if(dim2 === `widget`) dim2 = `widget_id`;
    const { publisherColumns } = columns;
    return publisherColumns.filter(col => {
      if(col.id === dim1) return true;
      if(col.id === dim2) return true;
      if(exclCols.includes(col.id)) return false;
      return !col.dimension;
    });
  },
);

export const selectDeviceOptions = createSelector(
  [selectStatsByDim],data => {
    const devices = data.map(d => d.device);
    return Array.from(new Set(devices)).sort();
  },
);

export const selectStatsByDomainAndWidgetEndpoint = createSelector(
  [state => state.auth.data.decoded.user_id,
    selectStartDate,selectEndDate],
  (userId,startDate,endDate) => {
    let endpoint = `/stats/publisher?`;
    const queryParams = [
      `dimensions=[domain,widget]`,
      `startDate=${startDate}`,
      `endDate=${endDate}`,
      `user_id=${userId}`,
    ];
    endpoint += queryParams.join(`&`);
    return endpoint;
  },
);

export const selectDomainOptions = createSelector(
  [selectStatsByDomainAndWidgetEndpoint,selectEndpoints],
  (endpoint,endpoints) => {
    const { data = [] } = endpoints[endpoint];
    const domains = data.map(d => d.domain);
    const output = Array.from(new Set(domains)).sort();
    return output;
  },
);

export const selectWidgetOptions = createSelector(
  [selectStatsByDomainAndWidgetEndpoint,selectEndpoints],
  (endpoint,endpoints) => {
    const { data = [] } = endpoints[endpoint];
    return data;
  },
);

export const selectCountryOptions = createSelector(
  [selectStatsByDim],data => {
    const countries = data.map(d => d.country);
    return Array.from(new Set(countries)).sort();
  },
);
