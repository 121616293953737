import React from 'react';
import { AlertTriangle } from 'react-feather';

const Alert = ({ msg = `Could not load data` }) => {
  return (
    <div
      style={{
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
        alignItems: `center`,
      }}>
      <AlertTriangle
        size={60}
        color={`#FF586B`}/>
      <div style={{ color: `#FF586B`,fontSize: 20 }}>{msg}</div>
    </div>
  );
};

export default Alert;