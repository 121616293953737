export const calcPublisherMetrics = data => data.map(d => {
  let { clicks,publisher_revenue,client_impressions,views } = { ...d };

  clicks = Number(clicks);
  publisher_revenue = Number(publisher_revenue);
  client_impressions = Number(client_impressions);
  views = Number(views);

  const output = {
    ...d,
    client_impressions,
    ctr: client_impressions ? clicks / client_impressions : 0,
    cpc: clicks ? publisher_revenue / clicks : 0,
    cpm: client_impressions ? (publisher_revenue / client_impressions) * 1000 : 0,
    vcpm: views ? (publisher_revenue / views) * 1000 : 0,
    vctr: views ? clicks / views : 0,
    viewability: client_impressions ? views / client_impressions : 0,
  };
  return output;
});

export const calcAdvertiserMetrics = data => data.map(d => {
  let { clicks,cost,impressions,viewable_impressions,conversions } = { ...d };

  clicks = Number(clicks);
  cost = Number(cost);
  impressions = Number(impressions);
  viewable_impressions = Number(viewable_impressions);

  const output = {
    ...d,
    impressions,
    ctr: impressions ? clicks / impressions : 0,
    vctr: viewable_impressions ? clicks / viewable_impressions : 0,
    cpc: clicks ? cost / clicks : 0,
    cpm: impressions ? (cost / impressions) * 1000 : 0,
    vcpm: viewable_impressions ? (cost / viewable_impressions) * 1000 : 0,
    viewability: impressions ? viewable_impressions / impressions : 0,
    conversion_rate: impressions ? Number((conversions / impressions).toFixed(4)) : 0,
    profit: d.return - cost,
    'return': d.return || 0,
  };
  return output;
});
