import { formatCommas,formatCurrency,formatDate,formatThousandsPercentage } from './formatter';

export const formatMetrics = data => {
  return data
    .map(d => {
      d = { ...d };
      if (!d) return {};
      d.cpc = formatCurrency(d.cpc);
      d.cpm = formatCurrency(d.cpm);
      d.ctr = formatThousandsPercentage(d.ctr);
      d.vctr = formatThousandsPercentage(d.vctr);
      if (d.date) d.date = formatDate(d.date);
      d.client_impressions = formatCommas(d.client_impressions);
      d.clicks = formatCommas(d.clicks);
      d.publisher_revenue = formatCurrency(d.publisher_revenue);
      d.vcpm = formatCurrency(d.vcpm);
      d.viewability = formatThousandsPercentage(d.viewability);
      d.views = formatCommas(d.views);
      return { ...d };
    });
};