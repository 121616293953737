import React,{ useState } from 'react';
import styled from 'styled-components';
import { Button,ButtonGroup as RSButtonGroup } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import SpinnerComponent from './spinner/spinner';

const ButtonGroup = ({ options,value,onChange,status }) => {
  const [lastButtonClicked,setLastButtonClicked] = useState();
  return (
    <Container>
      <RSButtonGroup>
        {options.map((option,index) => {
          const { id,label,tooltip,icon,color,backgroundColor } = option;
          const loading = lastButtonClicked === index && status === `loading`;
          const active = !!(typeof value !== `undefined` && value.length && value.includes(id));
          return (
            <StyledButton
              key={index}
              data-tip={tooltip}
              onClick={() => {
                setLastButtonClicked(index);
                onChange(id);
              }}
              style={{ backgroundColor,color }}
              active={active}>
              {loading && <SpinnerComponent size={20} color={color ? `white` : ``}/>}
              {!loading && icon}
              {!loading && label}
            </StyledButton>
          );
        })}
      </RSButtonGroup>
      <ReactTooltip
        delayShow={200}
        multiline={true}/>
    </Container>
  );
};

export default ButtonGroup;

const Container = styled.div`
  display:flex;
  flex-wrap:nowrap;

  .btn{
    color:rgb(73,80,87);
    margin-bottom:0;
    border-radius: 6px;
    border-color:var(--black-white) !important;
    background-color: rgb(211,211,211);
  }
  .btn.active{
    border-color:var(--black-white) !important;
    background-color:var(--button-color) !important;
  }
  .btn:focus{
    outline:var(--highlight);
    box-shadow: none !important;
  }
`;

const StyledButton = styled(Button)`
  background-color:var(--color1);
  color:${p => p.active ? `white !important` : ``};
  white-space:nowrap;
`;