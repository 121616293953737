import { createSlice } from '@reduxjs/toolkit';

const name = `campaignsStatsTotals`;

const slice = createSlice({
  name,
  initialState: {
    data: [],
    status: `idle`,
    error: null,
  },
  reducers: {
    setCampaignsStatsTotals(state,action){
      state.data = action.payload;
    },
    setCampaignsStatsTotalsSlice(state,action){
      return action.payload;
    },
  },
});

export default slice.reducer;
export const { setCampaignsStatsTotals,setCampaignsStatsTotalsSlice } = slice.actions;
export const selectCampaignsStatsTotals = state => state[name].data;
export const selectCampaignsStatsTotalsStatus = state => state[name].status;
export const selectCampaignsStatsTotalsSlice = state => state[name];