import qs from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { selectUrlState } from '../redux/url.slice';

const Link = ({ to,children,color,disabled }) => {
  const urlState = useSelector(selectUrlState);
  const queryString = qs.stringify(urlState);
  const { user } = useUser();
  let newLocation = user && user.email
    ? `${to}?` + queryString
    : to;
  const style = color ? { color } : {};

  const handleClick = async e => {
    if(disabled) e.preventDefault();
  };

  return (
    <NavLink
      onClick={handleClick}
      to={newLocation}
      exact
      activeClassName="active"
      style={style}>
      {children}
    </NavLink>
  );
};

export default Link;