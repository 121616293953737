import { createAsyncThunk,createSlice,createSelector } from '@reduxjs/toolkit';
import config from '../config';
import axios from 'axios';
import { selectJwt,selectUserId } from './auth.slice';
import { selectView } from './options.slice';

const name = `adUrls`;
const { SCHEME,HOST } = config;

export const fetchData = createAsyncThunk(`${name}/fetchData`,async(_,{ rejectWithValue,getState }) => {
  const state = getState();
  try{
    const response = await axios({
      method: `get`,
      url: `${SCHEME}://${HOST}/${name}`,
      headers: { 'Authorization': `Bearer ${selectJwt(state)}` },
    });
    return response.data.data || [];
  }catch(err){
    return rejectWithValue({ msg: err.response.data.message });
  }
},{
  condition: (_,{ getState }) => {
    const state = getState();
    return !!selectUserId(state)
    && state.url.parsed
    && selectView(state) === `advertiser`;
  },
});

export const createAdUrl = createAsyncThunk(`${name}/create`,async(action,{ getState,dispatch }) => {
  dispatch(add(action));
});

export const updateAdUrl = createAsyncThunk(`${name}/update`,async(action,{ getState,dispatch }) => {
  dispatch(update(action));
});

export const deleteAdUrl = createAsyncThunk(`${name}/delete`,async(action,{ getState,dispatch }) => {
  dispatch(remove(action));
});


const initialState = {
  data: [
    {
      id: 153,
      adGroupId: 123,
      url: `www.magicstuff.com/one/2412`,
    },
    {
      id: 827,
      adGroupId: 123,
      url: `www.magicstuff.com/one/afa2`,
    },
    {
      id: 839,
      adGroupId: 456,
      url: `www.magicstuff.com/one/afa2`,
    },
    {
      id: 830,
      adGroupId: 457,
      url: `www.magicstuff.com/one/afa2`,
    },
    {
      id: 831,
      adGroupId: 457,
      url: `www.magicstuff.com/one/afa2`,
    },
    {
      id: 832,
      adGroupId: 457,
      url: `www.magicstuff.com/one/afa2`,
    },
  ],
  status: `idle`,
  error: null,
};
const slice = createSlice({
  name,
  initialState,
  reducers: {
    add(state,action){
      const newId = Math.max(...state.data.map(i => i.id).map(Number)) + 1;
      state.data.push({ id: newId,...action.payload });
    },
    update(state,action){
      const { id,...updates } = { ...action.payload };
      state.data.forEach((item,index) => {
        if(item.id === id) state.data[index] = { ...item,...updates };
      });
    },
    remove(state,action){
      const output = [];
      state.data.forEach(item => {
        if(item.id !== action.payload)
          output.push(item);
      });
      state.data = output;
    },
  },
  extraReducers: {
    [fetchData.pending]: (state,action) => {
      state.status = `loading`;
      state.data = [];
    },
    [fetchData.fulfilled]: (state,action) => {
      state.status = `succeeded`;
      state.data = action.payload;
    },
    [fetchData.rejected]: (state,action) => {
      state.status = `failed`;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
export const { add,update,remove } = slice.actions;
export const selectAdUrls = state => state[name].data;
export const selectAdUrlIds = createSelector(
  [selectAdUrls],
  adUrls => {
    return adUrls.map(a => a.id);
  });