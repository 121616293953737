import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import config from '../config';
import { selectJwt,selectUserId } from './auth.slice';
import axios from 'axios';
import moment from 'moment';

const name = `campaigns`;
const { SCHEME,HOST } = config;

export const createCampaign = createAsyncThunk(`${name}/createCampaign`,
  async(linkTo,{ rejectWithValue,getState,dispatch }) => {
    const state = getState();
    const jwt = selectJwt(state);
    const data = {
      name: `New Campaign`,
      cpc: `0.001`,
      start_date: `immediately`,
      end_date: `never`,
      traffic_types: [3932916,3934002,3932692],
      country_targeting: `all`,
      region_targeting: `all`,
      zipcode_targeting: `all`,
      dma_targeting: `all`,
      devices: `all`,
      browsers: `all`,
      languages: `all`,
    };
    try{
      const response = await axios({
        method: `post`,
        url: `${SCHEME}://${HOST}/campaigns`,
        headers: { 'Authorization': `Bearer ${jwt}` },
        data,
      });
      const campaign_id = response.data.data.id;
      dispatch(addCampaign({ id: campaign_id,...data }));
      linkTo(`/campaigns/settings`);
    }catch(err){
      return rejectWithValue({ msg: err.response.data.message });
    }
  },{ condition: (_,{ getState }) => !!selectUserId(getState()) });

export const cloneCampaign = createAsyncThunk(`${name}/cloneCampaign`,
  async({ campaign_id,link },{ rejectWithValue,getState,dispatch }) => {
    const state = getState();
    const jwt = selectJwt(state);
    const campaigns = selectCampaigns(state);
    const campaign = campaigns.find(c => c.id === campaign_id);

    const data = { ...campaign };
    Object.keys(data).forEach(key => {
      if(!data[key]) delete data[key];
    });
    `user_id, type, overspend_processed, bid_type, targeting_type, domain_tag_targeting, interest_targeting, widget_targeting, brand_type, conversion_rate, created, updated, last_status_update, languages, browsers, devices`.split(`, `).forEach(key => {
      delete data[key];
    });
    moment(data.start_date).isBefore(moment())
      ? data.start_date = `immediately`
      : data.start_date = moment(data.start_date).format(`YYYY-MM-DD HH:mm:ss`);

    try{
      const response = await axios({
        method: `post`,
        url: `${SCHEME}://${HOST}/campaigns`,
        headers: { 'Authorization': `Bearer ${jwt}` },
        data,
      });
      const newCampaign = response.data.data;
      dispatch(addCampaign(response.data.data));
      link(`/campaigns/${newCampaign.id}/settings`);
    }catch(err){
      console.log(err.response.data);
      return rejectWithValue({ msg: err.response.data.message });
    }
  },{ condition: (_,{ getState }) => !!selectUserId(getState()) });

const slice = createSlice({
  name,
  initialState: {
    data: [],
    status: `idle`,
    error: null,
  },
  reducers: {
    setCampaigns(state,action){
      state.data = action.payload;
    },
    setCampaignsSlice(state,action){
      return action.payload;
    },
    addCampaign(state,action){
      state.data.push(action.payload);
    },
    removeCampaign(state,action){
      state.data = state.data.filter(c => c.id !== action.payload);
      return state.data;
    },
    updateCampaign(state,action){
      const { id,...updates } = action.payload;
      state.data.forEach(campaign => {
        if(campaign.id === id)
          Object.keys(updates).forEach(key => {
            campaign[key] = updates[key];
          });
      });

    },
  },
});

export default slice.reducer;
export const {
  setCampaigns,setCampaignsSlice,
  addCampaign,updateCampaign,removeCampaign,
} = slice.actions;
export const selectCampaigns = state => state[name].data;
export const selectCampaignsStatus = state => state[name].status;