import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import config from '../config';
import { selectJwt,selectUserId } from './auth.slice';
import axios from 'axios';

const name = `campaignBlacklist`;
const { SCHEME,HOST } = config;

export const addToCampaignBlacklist = createAsyncThunk(`${name}/add`,async({ list,campaign_id },{ rejectWithValue,getState,dispatch }) => {
  const state = getState();
  const jwt = selectJwt(state);
  console.log({ list });
  try{
    const response = await axios({
      method: `post`,
      url: `${SCHEME}://${HOST}/campaigns/${campaign_id}/blacklist`,
      headers: { 'Authorization': `Bearer ${jwt}` },
      data: list.map(id => Number(id)),
    });
    dispatch(setCampaignBlacklist([...selectCampaignBlacklist(state),...response.data.data]));
  }catch(err){
    console.log(err.response.data);
    return rejectWithValue({ msg: err.response.data.message });
  }
},{ condition: (_,{ getState }) => !!selectUserId(getState()) });

export const removeFromCampaignBlacklist = createAsyncThunk(`${name}/remove`,async({ list,campaign_id },{ rejectWithValue,getState,dispatch }) => {
  const state = getState();
  const jwt = selectJwt(state);
  try{
    await axios({
      method: `patch`,
      url: `${SCHEME}://${HOST}/campaigns/${campaign_id}/blacklist/delete`,
      headers: { 'Authorization': `Bearer ${jwt}` },
      data: list.map(Number),
    });
    dispatch(setCampaignBlacklist(selectCampaignBlacklist(state).filter(b => !list.includes(b.widget_id))));
  }catch(err){
    return rejectWithValue({ msg: err.response.data.message });
  }
},{ condition: (_,{ getState }) => !!selectUserId(getState()) });

const slice = createSlice({
  name,
  initialState: {
    data: [],
    status: `idle`,
    error: null,
  },
  reducers: {
    setCampaignBlacklist: (state,action) => {
      state.data = action.payload;
    },
  },
});

export default slice.reducer;
export const { setCampaignBlacklist } = slice.actions;
export const selectCampaignBlacklist = state => state[name].data;