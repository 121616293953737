import { routes } from './routes';

export const branding = {
  netizen: {
    registration_email: `support@netizen.co`,
    app_url: `www.netizen.co`,
    privacy_policy_url: `https://www.netizen.co/privacy-policy`,
    api_documentation_url: `https://api.netizen.co`,
  },
  revcontent: {
    registration_email: `support@revcontent.co,`,
    app_url: `www.revcontent.com`,
    privacy_policy_url: `https://www.revcontent.com/privacy-policy`,
    api_documentation_url: `https://api.revcontent.com`,
    support_ticket_url: `https://www.revcontent.com/contact/`,
  },
}[process.env.REACT_APP_BRANDING];

export const documentationLinks = { keywee: `https://google.com` };
const universal = {
  branding: process.env.REACT_APP_BRANDING,
  fallbackSupport: { email: process.env.REACT_APP_REGISTRATION_EMAIL },
  routes,
  dimensions: [
    { id: `country`,label: `Country`,type: `other` },
    { id: `device`,label: `Device`,type: `other` },
    { id: `domain`,label: `Domain`,type: `other` },
    // { id: `os`,label: `OS`,type: `other` },
    { id: `url`,label: `URL`,type: `other` },
    { id: `widget`,label: `Widget`,type: `other` },
    { id: `date`,label: `Date`,type: `date` },
    { id: `week`,label: `Week`,type: `date` },
    { id: `month`,label: `Month`,type: `date` },
  ],
  charts: [
    { id: `publisher_revenue`,label: `Revenue`,units: `$` },
    { id: `client_impressions`,label: `Impressions` },
    { id: `ctr`,label: `CTR`,percentage: true },
    { id: `cpc`,label: `CPC`,units: `$` },
    { id: `cpm`,label: `CPM`,units: `$` },
    { id: `vcpm`,label: `vCPM`,units: `$` },
    { id: `viewability`,label: `Viewability`,percentage: true },
  ],
  filters: [
    { id: `widgets`,label: `Widgets` },
    { id: `devices`,label: `Devices` },
    { id: `domains`,label: `Domains` },
    { id: `countries`,label: `Countries` },
  ],
};

export default {
  ...universal,
  SCHEME: `HTTPS`,
  HOST: process.env.REACT_APP_APEX_API_URL,
};
