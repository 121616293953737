import moment from 'moment';

export const datesBetweenDates = function(startDate,endDate) {
  var dates = [];

  var currDate = moment(startDate).startOf(`day`).subtract(1,`days`);
  var lastDate = moment(endDate).startOf(`day`);

  while(currDate.add(1,`days`).diff(lastDate) <= 0)
    dates.push(currDate.clone().format(`YYYY-MM-DD`));


  return dates;
};