import { useDispatch,useSelector } from 'react-redux';
import { getEndpoint,selectEndpoints } from '../redux/endpoints.slice';

export const useEndpoint = endpoint => {
  const dispatch = useDispatch();
  const endpoints = useSelector(selectEndpoints);
  if(!endpoint) return { data: null,page: 0,perPage: 0,numPages: 0 };
  if(!endpoints[endpoint] || !endpoints[endpoint].status)
    dispatch(getEndpoint(endpoint));
  return endpoints[endpoint] || {};
};

export const useEndpointStatus = endpoint => {
  const endpoints = useSelector(selectEndpoints);
  if(endpoints[endpoint] && endpoints[endpoint].status) return endpoints[endpoint].status;
  return null;
};