import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import config from '../config';
import { resetUrlSlice } from './url.slice';
import { resetStore } from './reset.thunk';

const { SCHEME,HOST } = config;

export const login = createAsyncThunk(`auth/login`,async(payload,{ dispatch,rejectWithValue,getState }) => {
  dispatch(setAuth(initialState.data));
  const { email,password,history } = payload;
  try {
    const response = await axios.post(`${SCHEME}://${HOST}/authentication`,
      { email,password,strategy: `local` },{ headers: { 'Access-Control-Allow-Origin': `*` } });
    const jwt = response.data && response.data.accessToken;
    const decoded = jwt_decode(jwt);
    if (!decoded.impersonating) localStorage.setItem(`jwt`,jwt);
    dispatch(setAuth({ jwt,decoded }));
    if(decoded.role.includes(`admin`))
      return history.push(`/impersonation`);
    history.push(`/`);
  } catch (err) {
    return rejectWithValue({ msg: err.response.data.message });
  }
});

export const logout = () => {
  return dispatch => {
    localStorage.removeItem(`jwt`);
    dispatch(resetUrlSlice());
    dispatch(setAuth(initialState.data));
    dispatch(resetStore());
  };
};

const initialState = {
  data: {
    jwt: null,
    decoded: { user_id: null,intent: `` },
  },
  status: `idle`,
  error: null,
};

const slice = createSlice({
  name: `auth`,
  initialState,
  reducers: {
    setAuth(state,action) {
      state.data = action.payload;
    },
    resetAuthError(state){
      state.error = null;
    },
  },
  extraReducers: {
    [login.pending]: (state,action) => {
      state.status = `loading`;
    },
    [login.fulfilled]: state => {
      state.status = `succeeded`;
    },
    [login.rejected]: (state,action) => {
      state.status = `failed`;
      state.data = initialState.data;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
export const selectJwt = state => state.auth.data.jwt;
export const selectLoginError = state => state.auth.error;
export const selectUserId = state => state.auth.data.decoded.user_id;
export const selectUserEmail = state => state.auth.data.decoded.email;
export const selectUserRole = state => state.auth.data.decoded.role;
export const selectImpersonating = state => state.auth.data.decoded.impersonating;
export const selectIntent = state => {
  return state.auth.data.decoded ? state.auth.data.decoded.intent : ``;
};
export const { setAuth,resetAuthError } = slice.actions;

