import { createSelector,createSlice } from '@reduxjs/toolkit';
import { calcPublisherMetrics } from '../utility/calcMetrics';

const name = `statsByDomain`;

const initialState = {
  data: [],
  status: `idle`,
  error: null,
};
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setStatsByDomainSlice(state,action){
      state.data = action.payload;
    },
    resetStatsByDomainSlice(state,action){
      state = initialState;
    },
  },
});

export default slice.reducer;
export const { setStatsByDomainSlice: setStatsByDomain,resetStatsByDomainSlice } = slice.actions;
export const selectStatsByDomain = state => state[name].data;
export const selectStatsByDomainStatus = state => state[name].status;
export const selectStatsByDomainSlice = state => state[name];


export const selectMetricsByDomain = createSelector(
  [selectStatsByDomain],
  data => {
    data = calcPublisherMetrics(data);
    return data;
  },
);

export const selectRevenueFromTopDomains = createSelector(
  [selectMetricsByDomain],
  data => {
    data = data.reduce((acc,cur) => {
      if(!acc.find(item => item.domain === cur.domain)) acc.push({ domain: cur.domain,publisher_revenue: 0 });
      acc.find(item => item.domain === cur.domain).publisher_revenue += cur.publisher_revenue;
      return acc;
    },[]).sort((a,b) => b.publisher_revenue - a.publisher_revenue).slice(0,4);
    return data;
  },
);

export const selectRevenueDeviceBreakdown = createSelector(
  [selectMetricsByDomain],
  data => {
    data = data.reduce((acc,cur) => {
      if(!acc.find(item => item.device === cur.device)) acc.push({ device: cur.device,publisher_revenue: 0 });
      acc.find(item => item.device === cur.device).publisher_revenue += cur.publisher_revenue;
      return acc;
    },[]).sort((a,b) => b.publisher_revenue - a.publisher_revenue);
    return data;
  },
);

export const selectRevenueCountryBreakdown = createSelector(
  [selectMetricsByDomain],
  data => {
    data = data.reduce((acc,cur) => {
      if(!acc.find(item => item.country === cur.country)) acc.push({ country: cur.country,publisher_revenue: 0 });
      acc.find(item => item.country === cur.country).publisher_revenue += cur.publisher_revenue;
      return acc;
    },[]).sort((a,b) => b.publisher_revenue - a.publisher_revenue);
    return data;
  },
);