import { createSlice } from '@reduxjs/toolkit';

const defaultValue = localStorage.getItem(`folded`) === `true` || false;
const name = `options`;

const slice = createSlice({
  name,
  initialState: {
    folded: defaultValue,
    adsNotification: true,
    recaptchaStatus: false,
    view: `publisher`,
  },
  reducers: {
    setView(state,action) {
      state.view = action.payload;
    },
    setTable(state,action) {
      state.table = action.payload;
    },
    setFolded(state,action) {
      state.folded = action.payload;
    },
    setRecaptchaStatus(state,action){
      state.recaptchaStatus = action.payload;
    },
  },
});

export default slice.reducer;
export const selectView = state => state[name].view;
export const selectTable = state => state[name].table;
export const selectFolded = state => state[name].folded;
export const selectRecaptchaStatus = state => state[name].recaptchaStatus;
export const { setView,setTable,setEditSettings,setImpersonating,setRecaptchaStatus } = slice.actions;

export const setFolded = payload => {
  localStorage.setItem(`folded`,payload);
  return (dispatch,getState) => {
    dispatch({ type: `options/setFolded`,payload });
  };
};

