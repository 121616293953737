import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import config from '../config';
import { selectJwt } from '../redux/auth.slice';
import { toastr } from 'react-redux-toastr';

const{ SCHEME,HOST } = config;

export const useApi = () => {
  const jwt = useSelector(selectJwt);
  const [status,setStatus] = useState(`idle`);
  const apiCall = async({ method = `get`,endpoint,data }) => {
    setStatus(`loading`);
    try{
      const axiosConfig = {
        method,
        url: `${SCHEME}://${HOST}${endpoint}`,
        headers: { authorization: `Bearer ${jwt}` },
        data,
      };
      const response = await axios(axiosConfig);
      setStatus(`succeeded`);
      return response;
    }catch(err){
      if(err.response && err.response.data && err.response.data.data && err.response.data.data.humanReadableMessage){
        console.log(err.response.data.data.humanReadableMessage.join(`. `));
        toastr.error(err.response.data.data.humanReadableMessage.join(`. `));
      }
      else if(err.response && err.response.data && err.response.data.errors && err.response.data.errors.length){
        console.log(err.response.data.errors.join(`. `));
        toastr.error(err.response.data.errors.join(`. `));
      }
      else if(err.response && err.response.data && err.response.data.message)
        toastr.error(err.response.data.message);
      else
        toastr.error(err.message);
      setStatus(`failed`);
      throw err;
    }
  };

  return [apiCall,status];
};