export const routes = [
  { path: `/`,protectedRoute: true,lazy: `Home` },
  { path: `/publisher/dashboard`,protectedRoute: true,view: `publisher`,lazy: `PublisherDashboard`,showDates: true },
  { path: `/advertiser/dashboard`,protectedRoute: true,view: `advertiser`,lazy: `AdvertiserDashboard`,showDates: true },
  { path: `/activate`,fullPage: true,lazy: `Activate` },
  { path: `/addresses`,protectedRoute: true,view: `advertiser`,lazy: `Addresses` },
  { path: `/ads-text`,protectedRoute: true,lazy: `AdsTxt` },
  { path: `/ads/:ad_id`,protectedRoute: true,view: `advertiser`,lazy: `Ad` },
  { path: `/adgroups/:adGroupId`,protectedRoute: true,view: `advertiser`,lazy: `AdGroupConfigurator` },
  { path: `/adgroups`,protectedRoute: true,view: `advertiser`,lazy: `AdGroups` },
  { path: `/ads`,protectedRoute: true,view: `advertiser`,lazy: `Ads`,showDates: true },
  { path: `/auto-funding`,protectedRoute: true,view: `advertiser`,lazy: `AutoFunding` },
  { path: `/ads/configure`,protectedRoute: true,view: `advertiser`,lazy: `AdConfigurator` },
  { path: `/performance`,protectedRoute: true,view: `advertiser`,lazy: `Performance` },
  { path: `/campaigns/blacklist`,protectedRoute: true,view: `advertiser`,lazy: `Blacklist` },
  { path: `/campaigns/new`,protectedRoute: true,view: `advertiser`,lazy: `NewCampaign` },
  { path: `/campaigns/:campaign_id`,protectedRoute: true,view: `advertiser`,lazy: `CampaignSettings`,showDates: false },
  { path: `/campaigns/:campaign_id/ads`,protectedRoute: true,view: `advertiser`,lazy: `CampaignAds`,showDates: true },
  { path: `/campaigns/:campaign_id/ads/create`,protectedRoute: true,view: `advertiser`,lazy: `CreateAd` },
  { path: `/campaigns/:campaign_id/blacklist`,protectedRoute: true,view: `advertiser`,lazy: `Blacklist`,showDates: true },
  { path: `/campaigns/:campaign_id/targeting`,protectedRoute: true,view: `advertiser`,lazy: `CampaignTargeting`,showDates: true },
  { path: `/campaigns/:campaign_id/performance`,protectedRoute: true,view: `advertiser`,lazy: `CampaignPerformance`,showDates: true },
  { path: `/campaigns`,protectedRoute: true,view: `advertiser`,lazy: `Campaigns` },
  { path: `/change_password`,fullPage: true,lazy: `ChangePassword` },
  { path: `/documentation`,protectedRoute: true,lazy: `Documentation` },
  { path: `/forgot_password`,fullPage: true,lazy: `ForgotPassword` },
  { path: `/funds`,protectedRoute: true,view: `advertiser`,lazy: `Funds` },
  { path: `/impersonate/:email`,protectedRoute: true,lazy: `Impersonate` },
  { path: `/impersonation`,protectedRoute: true,lazy: `Impersonation` },
  { path: `/notifications`,protectedRoute: true,lazy: `Notifications` },
  { path: `/login`,fullPage: true,lazy: `Login` },
  { path: `/payee`,protectedRoute: true,view: `advertiser`,lazy: `Payee` },
  { path: `/payment-methods`,protectedRoute: true,view: `advertiser`,lazy: `PaymentMethods` },
  { path: `/reporting`,protectedRoute: true,view: `publisher`,lazy: `Reporting`,showDates: true },
  { path: `/resend_activation`,fullPage: true,lazy: `ResendActivation` },
  { path: `/settings`,protectedRoute: true,lazy: `Settings` },
  { path: `/signup`,fullPage: true,lazy: `SignUp` },
  { path: `/transactions`,protectedRoute: true,view: `advertiser`,lazy: `Transactions` },
];