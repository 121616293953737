import { useEndpoint } from './useEndpoint';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const useCampaignTargetingWidgetsEndpoint = () => {
  const { campaign_id } = useParams();
  const { page,perPage } = useSelector(s => s.url);
  const endpoint = `/campaigns/${campaign_id}/widgets?offset=${page}&limit=${perPage}`;
  return endpoint;
};

export const useCampaignTargetingWidgets = () => {
  const endpoint = useCampaignTargetingWidgetsEndpoint();
  const { data = [],status,...rest } = useEndpoint(endpoint);
  return { widgets: data || [],status,...rest };
};