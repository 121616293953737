import CampaignActions from './components/campaigns/campaignActions';
import CampaignToggle from './components/campaigns/campaignToggle';
import SystemStatus from './components/systemStatus';
import EndDate from './components/endDate';
import AdPreview from './components/ads/adPreview';
// import AdCampaignName from './components/ads/adCampaignName';
// import AdActions from './components/ads/adActions';
import AdToggle from './components/ads/adToggle';
import AdminStatus from './components/adminStatus';
import CampaignId from './components/campaignId';
import CampaignName from './components/campaignName';
import Bid from './components/bid';
import CampaignTargetingActions from './components/campaignTargeting/campaignTargetingActions';
import AdId from './components/adId';
import AdActions from './components/ads/adActions';
// import AdCampaignId from './components/adCampaignId';

export const columns = {
  publisherColumns: [
    { id: `id`,label: `ID`,sortMethod: `text`,dimension: true },
    { id: `name`,label: `Name`,sortMethod: `text`,dimension: true },
    { id: `domain`,label: `Domain`,sortMethod: `text`,dimension: true },
    { id: `date`,label: `Date`,sortMethod: `date`,dimension: true },
    { id: `day`,label: `Day`,sortMethod: `date`,dimension: true },
    { id: `week`,label: `Week`,sortMethod: `text`,dimension: true },
    { id: `month`,label: `Month`,sortMethod: `text`,dimension: true },
    { id: `country`,label: `Country`,sortMethod: `text`,dimension: true },
    { id: `widget_id`,label: `Widget`,sortMethod: `number`,dimension: true },
    { id: `device`,label: `Device`,sortMethod: `text`,dimension: true },
    { id: `url`,label: `URL`,sortMethod: `text`,dimension: true },
    { id: `client_impressions`,label: `Impressions`,sortMethod: `number`,type: `commas` },
    { id: `views`,label: `Views`,sortMethod: `number`,type: `commas` },
    { id: `viewability`,label: `Viewability`,sortMethod: `number`,type: `percentage` },
    { id: `ctr`,label: `CTR`,sortMethod: `number`,type: `percentage` },
    { id: `clicks`,label: `Clicks`,sortMethod: `number`,type: `commas` },
    { id: `cpc`,label: `CPC`,sortMethod: `number`,type: `currency` },
    { id: `cpm`,label: `CPM`,sortMethod: `number`,type: `currency` },
    { id: `vctr`,label: `vCTR`,sortMethod: `number`,type: `percentage` },
    { id: `vcpm`,label: `vCPM`,sortMethod: `number`,type: `currency` },
    { id: `publisher_revenue`,label: `Revenue`,sortMethod: `number`,type: `currency` },
  ],
  campaignColumns: [
    { id: `id`,label: `ID`,helperText: `Campaign ID`,Component: CampaignId },
    { id: `name`,label: `Name`,helperText: `Name`,Component: CampaignName },
    { id: `start_date`,label: `Start`,helperText: `Start Date`,type: `date`,textAlign: `right` },
    { id: `end_date`,label: `End`,helperText: `End Date`,Component: EndDate,type: `date`,textAlign: `right` },
    { id: `status`,label: `Enabled`,helperText: `Turn Campaign on/off`,csv: false,Component: CampaignToggle,textAlign: `center` },
    { id: `system_status`,label: `Status`,helperText: `System Status`,Component: SystemStatus,textAlign: `center` },
    { id: `budget_amount`,label: `Budget`,helperText: `Budget`,performanceMetric: true,type: `currency` },
    { id: `clicks`,label: `Clicks`,helperText: `Clicks`,performanceMetric: true,type: `commas` },
    { id: `conversions`,label: `Convs`,helperText: `Conversions`,performanceMetric: true,type: `commas` },
    { id: `cost`,label: `Cost`,helperText: `Cost`,performanceMetric: true,type: `currency` },
    { id: `cpc`,label: `CPC`,helperText: `Cost Per Click`,performanceMetric: true,type: `currency` },
    { id: `cpm`,label: `CPM`,helperText: `Cost Per 1,000 Impressions`,performanceMetric: true,type: `currency` },
    { id: `vcpm`,label: `vCPM`,helperText: `Cost Per 1,000 Viewable Impressions`,performanceMetric: true,type: `currency` },
    { id: `ctr`,label: `CTR`,helperText: `Click Through Rate`,performanceMetric: true,type: `percentage` },
    { id: `vctr`,label: `vCTR`,helperText: `Viewable Click Through Rate`,performanceMetric: true,type: `percentage` },
    { id: `impressions`,label: `Imps`,helperText: `Impressions`,performanceMetric: true,type: `number` },
    { id: `views`,label: `Views`,helperText: `Views`,performanceMetric: true,type: `number` },
    { id: `viewability`,label: `Viewability`,helperText: `Viewability`,performanceMetric: true,type: `percentage` },
    { id: `conversion_rate`,label: `CR`,helperText: `Conversion Rate`,performanceMetric: true,type: `percentage` },
    {
      id: `actions`,label: `Actions`,helperText: `Actions`,csv: false,Component: CampaignActions,textAlign: `center`,unsortable: true,
    },
  ],
  adColumns: [
    { id: `id`,label: `ID`,helperText: `Ad ID`,Component: AdId },
    { id: `preview`,label: `Preview`,helperText: `Ad Preview`,Component: AdPreview,textAlign: `center`,unsortable: true },
    // { id: `campaign_id`,label: `Campaign ID`,Component: AdCampaignId },
    // { id: `campaign_name`,label: `Campaign Name`,Component: AdCampaignName },
    { id: `status`,label: `Enabled`,helperText: `Enabled`,csv: false,Component: AdToggle,textAlign: `center` },
    // { id: `actions`,label: `Actions`,csv: false,Component: AdActions },
    { id: `created`,label: `Added`,helperText: `Added`,type: `date`,textAlign: `right` },
    { id: `admin_status`,label: `Status`,helperText: `Status`,Component: AdminStatus,textAlign: `center` },
    { id: `impressions`,label: `Imps`,helperText: `Impressions`,performanceMetric: true,type: `commas` },
    { id: `clicks`,label: `Clicks`,helperText: `Clicks`,performanceMetric: true,type: `commas` },
    { id: `conversions`,label: `Convs`,helperText: `Conversions`,performanceMetric: true,type: `commas` },
    { id: `ctr`,label: `CTR`,helperText: `Click Through Rate`,performanceMetric: true,type: `percentage` },
    // { id: `pvs`,label: `PV/S`,helperText: `Page Views Per Session`,type: `commas` },
    { id: `cpc`,label: `CPC`,helperText: `Cost Per Click`,performanceMetric: true,type: `currency` },
    { id: `cost`,label: `Cost`,helperText: `Cost`,performanceMetric: true,type: `currency` },
    { id: `return`,label: `Return`,helperText: `Return`,performanceMetric: true,type: `currency` },
    { id: `profit`,label: `Profit`,helperText: `Profit`,performanceMetric: true,type: `currency` },
    { id: `actions`,label: `Actions`,helperText: `Actions`,Component: AdActions,unsortable: true },
  ],
  targetingColumns: [
    { id: `widget_id`,label: `ID`,helperText: `Widget ID` },
    { id: `domain`,label: `Site`,helperText: `Site` },
    { id: `user_id`,label: `Pub ID`,helperText: `Publisher ID`,textAlign: `left`,type: `string` },
    { id: `impressions`,label: `Imps`,helperText: `Impressions`,type: `number` },
    { id: `views`,label: `Views`,helperText: `Views`,type: `number` },
    { id: `clicks`,label: `Clicks`,helperText: `Clicks`,type: `number` },
    { id: `ctr`,label: `CTR`,helperText: `Click Through Rate`,type: `percentage` },
    { id: `vctr`,label: `vCTR`,helperText: `Viewable Click Through Rate`,type: `percentage` },
    { id: `pvs`,label: `PV/S`,helperText: `Page Views Per Session`,type: `commas` },
    { id: `conversions`,label: `Convs`,helperText: `Conversions`,type: `number` },
    { id: `cost`,label: `Cost`,helperText: `Cost`,type: `currency` },
    { id: `cost_per_conversion`,label: `Cost/Conv`,helperText: `Cost Per Conversion`,type: `currency` },
    { id: `return`,label: `Return`,helperText: `Return`,type: `currency` },
    { id: `profit`,label: `Profit`,helperText: `Profit`,type: `currency` },
    { id: `avg_cpc`,label: `Avg CPC`,helperText: `Average Cost Per Click`,type: `currency` },
    { id: `avg_cpv`,label: `Avg CPV`,helperText: `Average Cost Per View`,type: `currency` },
    { id: `cpc_bid`,label: `CPC Bid`,helperText: `Cost Per Click Bid`,Component: Bid,type: `currency`,textAlign: `center` },
    { id: `cpv_bid`,label: `CPV Bid`,helperText: `Cost Per View Bid`,Component: Bid,type: `currency`,textAlign: `center` },
    { id: `actions`,label: `Actions`,helperText: `Actions`,csv: false,Component: CampaignTargetingActions,textAlign: `center` },
  ],
  performanceColumns: [
    { id: `date`,label: `Day`,helperText: `Day`,type: `date` },
    { id: `week`,label: `Week`,helperText: `Week` },
    { id: `month`,label: `Month`,helperText: `Month` },
    { id: `ad_id`,label: `Ad ID`,helperText: `Ad ID` },
    { id: `preview`,label: `Ad Preview`,helperText: `Ad Preview`,Component: AdPreview },
    { id: `impressions`,label: `Imps`,helperText: `Impressions`,performanceMetric: true,type: `number` },
    { id: `views`,label: `Views`,helperText: `Views`,performanceMetric: true,type: `number` },
    { id: `clicks`,label: `Clicks`,helperText: `Clicks`,performanceMetric: true,type: `commas` },
    { id: `conversions`,label: `Convs`,helperText: `Conversions`,performanceMetric: true,type: `commas` },
    { id: `viewability`,label: `Viewability`,helperText: `Viewability`,performanceMetric: true,type: `percentage`,hideInitially: true },
    { id: `conversion_rate`,label: `Convs Rate`,helperText: `Conversion Rate`,performanceMetric: true,type: `percentage`,hideInitially: true },
    { id: `ctr`,label: `CTR`,helperText: `Click Through Rate`,performanceMetric: true,type: `percentage` },
    { id: `vctr`,label: `vCTR`,helperText: `Viewable Click Through Rate`,performanceMetric: true,type: `percentage` },
    { id: `cpc`,label: `CPC`,helperText: `Cost Per Click`,performanceMetric: true,type: `currency` },
    { id: `cpm`,label: `CPM`,helperText: `Cost Per 1,000 Impressions`,performanceMetric: true,type: `currency`,hideInitially: true },
    { id: `vcpm`,label: `vCPM`,helperText: `Cost Per 1,000 Viewable Impressions`,performanceMetric: true,type: `currency`,hideInitially: true },
    { id: `cost`,label: `Cost`,helperText: `Cost`,performanceMetric: true,type: `currency` },
    { id: `cost_per_conversion`,label: `Cost/Conv`,helperText: `Cost Per Conversion`,performanceMetric: true,type: `currency` },
    { id: `return`,label: `Return`,helperText: `Return`,performanceMetric: true,type: `currency` },
    { id: `profit`,label: `Profit`,helperText: `Profit`,performanceMetric: true,type: `currency` },
  ],
};
