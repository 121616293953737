import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';
import { selectJwt } from './auth.slice';

const { SCHEME,HOST } = config;
const name = `impersonate`;

export const impersonate = createAsyncThunk(`admin/impersonate`,async(payload,{ getState,dispatch,rejectWithValue }) => {
  dispatch(setImpersonate(initialState));
  try{
    let url = `${SCHEME}://${HOST}/admin/impersonation/${payload}`;
    const jwt = selectJwt(getState());
    const response = await axios.get(url,{ headers: { 'Authorization': `Bearer ${jwt}` } });
    dispatch(setImpersonate(response.data));
  }catch(err){
    return rejectWithValue({ msg: err.response.data.message });
  }
});

const initialState = {
  data: [],
  status: `idle`,
  error: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setImpersonate(state,action){
      state.data = action.payload;
    },
    resetImpersonate(state,action){
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [impersonate.pending]: (state,action) => {
      state.status = `loading`;
    },
    [impersonate.fulfilled]: state => {
      state.status = `succeeded`;
    },
    [impersonate.rejected]: (state,action) => {
      state.status = `failed`;
      state.data = initialState.data;
      state.error = action.payload.msg;
    },
  },
});

export default slice.reducer;
export const selectImpersonate = state => state[name];
export const { setImpersonate,resetImpersonate } = slice.actions;

