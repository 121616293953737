import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import config from '../config';
import axios from 'axios';
import { selectJwt,selectUserId } from './auth.slice';
import { selectView } from './options.slice';

const name = `adGroups`;
const { SCHEME,HOST } = config;

export const fetchData = createAsyncThunk(`${name}/fetchData`,async(_,{ rejectWithValue,getState }) => {
  const state = getState();
  try{
    const response = await axios({
      method: `get`,
      url: `${SCHEME}://${HOST}/${name}`,
      headers: { 'Authorization': `Bearer ${selectJwt(state)}` },
    });
    return response.data.data || [];
  }catch(err){
    return rejectWithValue({ msg: err.response.data.message });
  }
},{
  condition: (_,{ getState }) => {
    const state = getState();
    return !!selectUserId(state)
    && state.url.parsed
    && selectView(state) === `advertiser`;
  },
});

export const createAdGroup = createAsyncThunk(`${name}/create`,async(action,{ getState,dispatch }) => {
  dispatch(add(action));
});

export const updateAdGroup = createAsyncThunk(`${name}/update`,async(action,{ getState,dispatch }) => {
  dispatch(update(action));
});

export const deleteAdGroup = createAsyncThunk(`${name}/delete`,async(action,{ getState,dispatch }) => {
  dispatch(remove(action));
});


const initialState = {
  data: [
    {
      id: 123,
      name: `one`,
      campaign_ids: [759390],
    },
    {
      id: 456,
      name: `four`,
      campaign_ids: [795884],
    },
    {
      id: 457,
      name: `brand takeover`,
      kind: `brand takeover`,
      embedId: `Nvedr1AeQt8`,
      campaign_ids: [759390],
      ads: [
        `578-826-830`,
        `579-827-831`,
        `580-828-832`,
      ],
    },
  ],
  status: `idle`,
  error: null,
};
const slice = createSlice({
  name,
  initialState,
  reducers: {
    add(state,action){
      const newId = Math.max(...state.data.map(i => i.id).map(Number)) + 1;
      state.data.push({
        id: newId,
        ...action.payload,
      });
    },
    update(state,action){
      const { id,...updates } = { ...action.payload };
      state.data.forEach((item,index) => {
        if(item.id === id) state.data[index] = { ...item,...updates };
      });
    },
    remove(state,action){
      const output = [];
      state.data.forEach(item => {
        if(item.id !== action.payload)
          output.push(item);
      });
      state.data = output;
    },
  },
  extraReducers: {
    [fetchData.pending]: (state,action) => {
      state.status = `loading`;
      state.data = [];
    },
    [fetchData.fulfilled]: (state,action) => {
      state.status = `succeeded`;
      state.data = action.payload;
    },
    [fetchData.rejected]: (state,action) => {
      state.status = `failed`;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
export const { add,update,remove } = slice.actions;
export const selectAdGroups = state => state[name].data;
export const selectAdGroupsStatus = state => state[name].status;