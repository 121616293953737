import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

const { SCHEME,HOST } = config;
const name = `resendActivation`;

export const resendActivation = createAsyncThunk(`resendActivation/request`,async(payload,{ dispatch,rejectWithValue }) => {
  dispatch(setResendActivation(initialState));
  const { email } = payload;
  try{
    const response = await axios.post(`${SCHEME}://${HOST}/account/resend_activation`,
      { email });
    return response.data;
  }catch(err){
    return rejectWithValue(err.response.data);
  }
});

const initialState = {
  data: {},
  status: `idle`,
  error: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setResendActivation(state,action){
      state.data = action.payload;
    },
    resetResendActivation(state,action){
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [resendActivation.pending]: (state,action) => {
      state.status = `loading`;
    },
    [resendActivation.fulfilled]: (state,action) => {
      state.status = `succeeded`;
      state.data = action.payload;
    },
    [resendActivation.rejected]: (state,action) => {
      state.status = `failed`;
      state.data = initialState.data;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
export const selectResendActivation = state => state[name];
export const { setResendActivation,resetResendActivation } = slice.actions;

