import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import config from '../config';
import { selectJwt } from './auth.slice';
import axios from 'axios';

const name = `endpoints`;
const { SCHEME,HOST } = config;

export const getEndpoint = createAsyncThunk(
  `${name}/get`,
  async(endpoint,{ getState,dispatch }) => {
    let reload = true;
    if(typeof endpoint === `object`){
      reload = endpoint.reload; //eslint-disable-line
      endpoint = endpoint.endpoint; //eslint-disable-line
    }
    if(reload) dispatch(pending({ endpoint }));
    const state = getState();
    if(state[endpoint] && state[endpoint].status === `loading`) return;
    try{
      // console.log(`fetching ${endpoint}`);
      const axiosConfig = {
        method: `get`,
        url: `${SCHEME}://${HOST}${endpoint}`,
        headers: { authorization: `Bearer ${selectJwt(state)}` },
      };
      const response = await axios(axiosConfig);
      dispatch(fulfilled({ endpoint,...response.data }));
    }catch(err){
      dispatch(rejected({ endpoint,error: { msg: err.response.data.message } }));
      console.log(err.message);
    }
  },
);
const slice = createSlice({
  name,
  initialState: {},
  reducers: {
    pending(state,action){
      const { endpoint } = action.payload;
      if(!state[endpoint])state[endpoint] = {};
      state[endpoint].status = `loading`;
      return state;
    },
    fulfilled(state,action){
      const { endpoint,...rest } = action.payload;
      state[endpoint] = { status: `succeeded`,...rest };
      return state;
    },
    rejected(state,action){
      const { endpoint,error } = action.payload;
      if(!state[endpoint])state[endpoint] = {};
      state[endpoint].status = `failed`;
      state[endpoint].error = error;
      return state;
    },
  },
});

export default slice.reducer;
export const { pending,fulfilled,rejected } = slice.actions;
export const selectEndpoints = state => state[name];