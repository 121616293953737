import styled from 'styled-components';
import React from 'react';
import ButtonGroup from '../buttonGroup';
import SettingsIcon from '@material-ui/icons/Settings';
import { Image,Target } from 'react-feather';
// import WidgetsIcon from '@material-ui/icons/Widgets';
import ArchiveIcon from '@material-ui/icons/Archive';
import BlockIcon from '@material-ui/icons/Block';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useDispatch } from 'react-redux';
import { setShowFilters,setUrlCampaigns } from '../../redux/url.slice';
import useLink from '../../hooks/useLink';
import { cloneCampaign } from '../../redux/campaigns.slice';
import { useCampaignsTableCampaignsEndpoint } from '../../hooks/useCampaignsTableCampaigns';
import { getEndpoint } from '../../redux/endpoints.slice';
import { useApi } from '../../hooks/useApi';

const CampaignActions = campaign => {
  const link = useLink();
  const dispatch = useDispatch();
  const [api] = useApi();
  const linkTo = useLink();
  const endpoint = useCampaignsTableCampaignsEndpoint();

  const handleChange = async e => {
    if(e === `settings`) return linkTo(`/campaigns/${campaign.id}`);
    if(e === `ads`) {
      dispatch(setUrlCampaigns([campaign.id]));
      dispatch(setShowFilters(true));
      return linkTo(`/campaigns/${campaign.id}/ads`);
    }
    if(e === `widget_targeting`) {
      dispatch(setUrlCampaigns([campaign.id]));
      return linkTo(`/campaigns/${campaign.id}/targeting`);
    }
    if(e === `blacklist`) {
      dispatch(setUrlCampaigns([campaign.id]));
      return linkTo(`/campaigns/${campaign.id}/blacklist`);
    }
    if(e === `archive`){
      if(!window.confirm(`Are you sure you want to archive this campaign?`)) return;
      await api({
        method: `delete`,
        endpoint: `/campaigns/${campaign.id}`,
      });
      return dispatch(getEndpoint({ endpoint,reload: false }));
    }
    if(e === `clone`)
      return dispatch(cloneCampaign({ campaign_id: campaign.id,link }));
    alert(`Not yet implemented`);
  };

  const options = [
    {
      id: `settings`,
      label: ``,
      icon: <SettingsIcon/>,
      tooltip: `Settings`,
    },
    {
      id: `ads`,
      label: ``,
      icon: <Image/>,
      tooltip: `Manage Ads`,
    },
    {
      id: `widget_targeting`,
      label: ``,
      icon: <Target/>,
      tooltip: `Widget Targeting`,
    },
    {
      id: `blacklist`,
      label: ``,
      icon: <BlockIcon/>,
      tooltip: `See Widget Blacklist`,
    },
    {
      id: `archive`,
      label: ``,
      icon: <ArchiveIcon/>,
      tooltip: `Archive`,
    },
    {
      id: `clone`,
      label: ``,
      icon: <FileCopyIcon/>,
      tooltip: `Clone`,
    },
  ];

  return (
    <Container>
      <ButtonGroup
        options={options}
        onChange={handleChange}/>

    </Container>
  );
};

export default CampaignActions;

const Container = styled.div`
  display:flex;
  justify-content:center;  
`;