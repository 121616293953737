import React from 'react';
import styled from 'styled-components';

const EndDate = ({ end_date }) => {
  const text = end_date || `never`;
  return (
    <Container>
      {text}
    </Container>
  );
};

export default EndDate;

const Container = styled.div`
  display:flex;
  text-align:right;
  justify-content:flex-end;
`;

