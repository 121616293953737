import React,{ useEffect,useState } from 'react';
import styled from 'styled-components';
import ButtonGroup from './buttonGroup';
import InputGroup from './inputGroup';
import { useApi } from '../hooks/useApi';
import { useDispatch } from 'react-redux';
import { getEndpoint } from '../redux/endpoints.slice';
import { useCampaignTargetingWidgetsEndpoint } from '../hooks/useCampaignTargetingWidgets';
import { useCampaign } from '../hooks/useCampaign';

const Bid = row => {
  const { campaign_id,widget_id,cpc = 0,cpv = 0 } = row;
  const [api,status] = useApi();
  const campaign = useCampaign();
  const endpoint = useCampaignTargetingWidgetsEndpoint();
  const dispatch = useDispatch();

  const { bid_type = `cpc` } = campaign || {};
  const initialValue = Number(String({ cpc,cpv }[bid_type]).replace(`$`,``));

  const [value,setValue] = useState(initialValue);

  useEffect(() => setValue(Number(String({ cpc,cpv }[bid_type]).replace(`$`,``))),[cpc,cpv,bid_type]);

  const handleButtonClick = async e => {
    let newValue = initialValue;
    if(e === `minus10percent`) newValue = Math.round(newValue * 0.9 * 100) / 100;
    if(e === `plus10percent`) newValue = Math.round(newValue * 1.1 * 100) / 100;
    setValue(newValue);
    await sendRequest({ [bid_type]: newValue });

  };
  const handleSubmit = async() => {
    await sendRequest({ [bid_type]: Number(value.replace(`$`,``)) });
  };

  const sendRequest = async data => {
    await api({
      method: `put`,
      endpoint: `/campaigns/${campaign_id}/widgets/${widget_id}`,
      data,
    });
    dispatch(getEndpoint(endpoint));
  };

  return (
    <Container>
      <InputGroup
        prepend="$"
        value={value}
        onChange={setValue}
        onSubmit={handleSubmit}/>
      <ButtonGroup
        status={status}
        options={[
          {
            id: `minus10percent`,
            label: `-10%`,
            color: `white`,
            backgroundColor: `red`,
          },
          {
            id: `plus10percent`,
            label: `+10%`,
            color: `white`,
            backgroundColor: `green`,
          },
        ]}
        onChange={handleButtonClick}/>
    </Container>
  );
};

export default Bid;

const Container = styled.div`
  display:flex;
  gap:10px;
`;