import moment from 'moment';

const toNum = value => Number(String(value).replace(/[%$,]/g,``));

export const sorter = (data,sortBy,sortAsc,method) => {
  if (!data.every(d => sortBy in d)) return data;

  data = data.sort((a,b) => {

    const one = a[sortBy];
    const two = b[sortBy];

    if (method === `date`) {
      const dateOne = moment(one).unix();
      const dateTwo = moment(two).unix();
      return sortAsc ? dateOne - dateTwo : dateTwo - dateOne;
    }
    if (method === `number`)
      return sortAsc ? toNum(one) - toNum(two) : toNum(two) - toNum(one);

    return sortAsc ? one.localeCompare(two) : two.localeCompare(one);
  });
  return [...data];
};