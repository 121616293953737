import React from 'react';
import styled from 'styled-components';
import Link from './link';

const CampaignId = ({ id }) => {
  return (
    <Link to={`/campaigns/${id}`}>
      <Container>
        {id}
      </Container>
    </Link>
  );
};

export default CampaignId;

const Container = styled.div`
  display:flex;
`;

