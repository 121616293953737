import React from 'react';
import styled from 'styled-components';
import ReactToggle from 'react-toggle';
import 'react-toggle/style.css';

const Toggle = ({ value,onChange }) => {
  const handleChange = e => {
    onChange(!value);
  };

  return (
    <Container>
      <ReactToggle
        checked={value}
        onChange={handleChange}/>
    </Container>
  );
};

export default Toggle;

const Container = styled.div`
  display:flex;
  justify-content:center;
`;