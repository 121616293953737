import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit';
import { reducer as toastrReducer } from 'react-redux-toastr';
import createSagaMiddleware from 'redux-saga';

import accountActivationReducer from './accountActivation.slice';
import resendActivationReducer from './resendActivation.slice';

import adGroupsReducer from './adGroups.slice';
import adMediaReducer from './adMedia.slice';
import adTextsReducer from './adTexts.slice';
import adUrlsReducer from './adUrls.slice';
import adsReducer from './ads.slice';
import adMetricsReducer from './adMetrics.slice';

import authReducer from './auth.slice';
import blacklistReducer from './blacklist.slice';
import campaignBlacklistReducer from './campaignBlacklist.slice';
import campaignsReducer from './campaigns.slice';
import campaignsStatsReducer from './campaignsStats.slice';
import campaignsStatsTotalsReducer from './campaignsStatsTotals.slice';
import adsStatsReducer from './adsStats.slice';
import impersonateReducer from './impersonate.slice';
import optionsReducer from './options.slice';

import signupReducer from './signup.slice';
import statsByDateReducer from './statsByDate.slice';
import statsByDimReducer from './statsByDim.slice';
import statsByDomainReducer from './statsByDomain.slice';
import statsByWidgetReducer from './statsByWidget.slice';
import statsReducer from './stats.slice';
import endpointsReducer from './endpoints.slice';
import urlReducer from './url.slice';

export const sagaMiddleware = createSagaMiddleware();

export default configureStore({
  reducer: {
    accountActivation: accountActivationReducer,
    endpoints: endpointsReducer,
    adTexts: adTextsReducer,
    adMedia: adMediaReducer,
    adUrls: adUrlsReducer,
    ads: adsReducer,
    adMetrics: adMetricsReducer,
    adGroups: adGroupsReducer,
    auth: authReducer,
    blacklist: blacklistReducer,
    campaignBlacklist: campaignBlacklistReducer,
    campaigns: campaignsReducer,
    campaignsStats: campaignsStatsReducer,
    campaignsStatsTotals: campaignsStatsTotalsReducer,
    adsStats: adsStatsReducer,
    impersonate: impersonateReducer,
    options: optionsReducer,
    resendActivation: resendActivationReducer,
    signup: signupReducer,
    stats: statsReducer,
    statsByDate: statsByDateReducer,
    statsByDim: statsByDimReducer,
    statsByDomain: statsByDomainReducer,
    statsByWidget: statsByWidgetReducer,
    toastr: toastrReducer,
    url: urlReducer,
  },
  middleware: [...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),sagaMiddleware],
});


