import { capitalCase } from 'change-case';
import React from 'react';
import styled from 'styled-components';
import useTheme from '../hooks/useTheme';

const SystemStatus = ({ system_status }) => {
  const theme = useTheme();
  const color = {
    active: `rgb(81,175,91)`,
    archived: theme[`--text-color`],
  }[system_status] || `red`;

  return (
    <Container color={color}>
      {capitalCase(system_status)}
    </Container>
  );
};

export default SystemStatus;

const Container = styled.div`
  display:flex;
  color:${p => p.color};
  justify-content:center;
`;

