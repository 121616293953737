import { capitalCase } from 'change-case';
import React from 'react';
import styled from 'styled-components';

const AdminStatus = ({ admin_status }) => {
  const color = {
    approved: `rgb(81,175,91)`,
    pending: `orange`,
    denied: `red`,
  }[admin_status] || `black`;

  return (
    <Container color={color}>
      {capitalCase(admin_status)}
    </Container>
  );
};

export default AdminStatus;

const Container = styled.div`
  display:flex;
  color:${p => p.color};
  text-align:center;
  justify-content:center;
`;

