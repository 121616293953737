import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

const { SCHEME,HOST } = config;
const name = `accountActivation`;

export const accountActivation = createAsyncThunk(`changePassword/sendNewPassword`,async(payload,{ dispatch,rejectWithValue }) => {
  dispatch(resetAccountActivation());
  try{
    const response = await axios.get(`${SCHEME}://${HOST}/account/activate?a=${payload.token}`,
      { headers: { 'Authorization': `Bearer ${localStorage.getItem(`jwt`)}` } });
    return response.data;
  }catch(err){
    return rejectWithValue(err.response.data);
  }
});

const initialState = {
  data: {},
  status: `idle`,
  error: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAccountActivation(state,action){
      state.data = action.payload;
    },
    resetAccountActivation(state,action){
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [accountActivation.pending]: (state,action) => {
      state.status = `loading`;
    },
    [accountActivation.fulfilled]: (state,action) => {
      state.status = `succeeded`;
      state.data = action.payload;
    },
    [accountActivation.rejected]: (state,action) => {
      state.status = `failed`;
      state.data = initialState.data;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
export const selectAccountActivation = state => state[name];
export const { setAccountActivation,resetAccountActivation } = slice.actions;

