import { createSlice } from '@reduxjs/toolkit';

const name = `stats`;

const initialState = {
  data: [],
  status: `idle`,
  error: null,
};
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setStats(state,action){
      state.data = action.payload;
    },
    resetStatsSlice(state,action){
      state = initialState;
    },
  },
});

export default slice.reducer;
export const { setStats,resetStatsSlice } = slice.actions;
export const selectStats = state => state.stats.data;
export const selectStatsStatus = state => state.stats.status;
export const selectStatsSlice = state => state.stats;