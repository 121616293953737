import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import qs from 'qs';
import { selectUserEmail } from './auth.slice';

const initialState = {
  page: 0,
  perPage: 10,
  sortBy: `client_impressions`,
  sortAsc: false,
  devices: [],
  domains: [],
  widgets: [],
  os: [],
  countries: [],
  domain: [],
  dim1: `widget`,
  dim2: ``,
  exclCols: [],
  showCharts: true,
  showFilters: false,
  endDate: moment().format(`YYYY-MM-DD`),
  startDate: moment().subtract(6,`days`).format(`YYYY-MM-DD`),
  mainMetric: `revenue`,
  metric1: `clicks`,
  metric2: `conversions`,
  campaigns: [],
  campaignsFreetext: ``,
  campaignsBidType: ``,
  campaignsStatus: `active`,
  campaignsSystemStatus: `active`,
  adsFreetext: ``,
  adsContentType: ``,
  adsStatus: `active`,
  adsAdminStatus: `approved`,
  ads: [],
  adGroups: [],
  breakdownBy: [],
  granularity: ``,
  parsed: false,
  impersonationSearch: ``,
};
const slice = createSlice({
  name: `url`,
  initialState,
  reducers: {
    nextPage(state) {
      state.page++;
    },
    previousPage(state) {
      state.page = Math.max(0,state.page - 1);
    },
    setUrlCampaigns(state,action) {
      state.campaigns = action.payload;
    },
    setUrlAdGroups(state,action) {
      state.adGroups = action.payload;
    },
    setUrlAds(state,action) {
      state.ads = action.payload;
    },
    setGranularity(state,action) {
      state.granularity = action.payload;
    },
    setPage(state,action) {
      state.page = action.payload;
    },
    setPerPage(state,action) {
      state.perPage = action.payload;
    },
    setSortBy(state,action) {
      state.sortBy = action.payload;
    },
    toggleSortAsc(state,action) {
      state.sortAsc = !state.sortAsc;
    },
    setUrlState(state,action) {
      return { ...state,...action.payload };
    },
    setInitialUrlState(state) {
      state = { ...initialState };
      return state;
    },
    setShowCharts(state,action) {
      state.showCharts = action.payload;
    },
    setShowFilters(state,action) {
      state.showFilters = action.payload;
    },
    setDimensions(state,action) {
      state.dimensions = action.payload;
    },
    setBreakdownBy(state,action) {
      state.breakdownBy = action.payload;
    },
    setDim1(state,action) {
      state.dim1 = action.payload;
    },
    setDim2(state,action) {
      state.dim2 = action.payload;
    },
    setExclCols(state,action) {
      state.exclCols = action.payload;
    },
    setStartDate(state,action) {
      state.startDate = action.payload;
    },
    setEndDate(state,action) {
      state.endDate = action.payload;
    },
    setStartAndEndDate(state,action) {
      state.endDate = action.payload.endDate;
      state.startDate = action.payload.startDate;
      return state;
    },
    setMetric1(state,action) {
      state.metric1 = action.payload;
    },
    setMetric2(state,action) {
      state.metric2 = action.payload;
    },
    setMainMetric(state,action) {
      state.mainMetric = action.payload;
    },
    setDevices(state,action) {
      state.devices = action.payload;
    },
    setWidgets(state,action) {
      state.widgets = action.payload;
    },
    setOs(state,action) {
      state.os = action.payload;
    },
    setCountries(state,action) {
      state.countries = action.payload;
    },
    setDomain(state,action) {
      state.domain = action.payload;
    },
    setDomains(state,action) {
      state.domains = action.payload;
    },
    setFilters(state,action) {
      state = { ...state,...action.payload };
    },
    resetUrlSlice(state,action) {
      state = initialState;
      return state;
    },
  },
});

export default slice.reducer;

export const selectUrlState = state => state.url;
export const selectDim1 = state => state.url.dim1;
export const selectDim2 = state => state.url.dim2;
export const selectBreakdownBy = state => state.url.breakdownBy;
export const selectUrlCampaigns = state => state.url.campaigns;
export const selectUrlAds = state => state.url.ads;
export const selectUrlAdGroups = state => state.url.adGroups;
export const selectGranularity = state => state.url.granularity;
export const selectDevices = state => state.url.devices;
export const selectDomains = state => state.url.domains;
export const selectWidgets = state => state.url.widgets;
export const selectCountries = state => state.url.countries;
export const selectDomain = state => state.url.domain;
export const selectShowCharts = state => state.url.showCharts;
export const selectShowFilters = state => state.url.showFilters;
export const selectStartDate = state => state.url.startDate;
export const selectExclCols = state => state.url.exclCols;
export const selectEndDate = state => state.url.endDate;
export const selectMetric1 = state => state.url.metric1;
export const selectMetric2 = state => state.url.metric2;
export const selectMainMetric = state => {
  let { mainMetric } = state.url;
  if (mainMetric === `impressions`) mainMetric = `client_impressions`;
  if (mainMetric === `revenue`) mainMetric = `publisher_revenue`;
  return mainMetric;
};
export const selectPerPage = state => Number(state.url.perPage);
export const selectPage = state => Number(state.url.page);
export const selectSortBy = state => state.url.sortBy;
export const selectSortAsc = state => state.url.sortAsc;

export const setUrlState = updates => updater(`setUrlState`,updates);
export const setInitialUrlState = updates => updater(`setInitialUrlState`,updates);
export const setQs = updates => updater(`setQs`,updates);
export const setUrlCampaigns = updates => updater(`setUrlCampaigns`,updates);
export const setUrlAds = updates => updater(`setUrlAds`,updates);
export const setUrlAdGroups = updates => updater(`setUrlAdGroups`,updates);
export const setGranularity = updates => updater(`setGranularity`,updates);
export const setDim1 = updates => updater(`setDim1`,updates);
export const setDim2 = updates => updater(`setDim2`,updates);
export const setBreakdownBy = updates => updater(`setBreakdownBy`,updates);
export const setShowCharts = updates => updater(`setShowCharts`,updates);
export const setShowFilters = updates => updater(`setShowFilters`,updates);
export const setStartDate = updates => updater(`setStartDate`,updates);
export const setEndDate = updates => updater(`setEndDate`,updates);
export const setStartAndEndDate = updates => updater(`setStartAndEndDate`,updates);
export const setMetric1 = updates => updater(`setMetric1`,updates);
export const setMetric2 = updates => updater(`setMetric2`,updates);
export const setMainMetric = updates => updater(`setMainMetric`,updates);
export const setWidgets = updates => updater(`setWidgets`,updates);
export const setDevices = updates => updater(`setDevices`,updates);
export const setOs = updates => updater(`setOs`,updates);
export const setCountries = updates => updater(`setCountries`,updates);
export const setDomain = updates => updater(`setDomain`,updates);
export const setDomains = updates => updater(`setDomains`,updates);
export const setExclCols = updates => updater(`setExclCols`,updates);
export const setFilters = updates => updater(`setFilters`,updates);
export const nextPage = updates => updater(`nextPage`,updates);
export const previousPage = updates => updater(`previousPage`,updates);
export const setPage = updates => updater(`setPage`,updates);
export const setPerPage = updates => updater(`setPerPage`,updates);
export const setSortBy = updates => updater(`setSortBy`,updates);
export const setSortAsc = updates => updater(`setSortAsc`,updates);
export const toggleSortAsc = updates => updater(`toggleSortAsc`,updates);
export const { resetUrlSlice } = slice.actions;

const updater = (actionName,updates) => (dispatch,getState) => {
  dispatch({
    type: `url/${actionName}`,
    payload: updates,
  });
  updateUrl(getState);
};

const updateUrl = getState => {
  const state = getState();
  const urlState = selectUrlState(state);
  const email = selectUserEmail(state);
  const queryString = qs.stringify(urlState);
  localStorage.setItem(`urlState`,JSON.stringify({ email,data: queryString }));
  let newLocation = `${window.location.pathname}?` + queryString;
  window.history.replaceState(null,null,newLocation);
};