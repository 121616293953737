import { createSlice } from '@reduxjs/toolkit';

const name = `campaignsStats`;

const slice = createSlice({
  name,
  initialState: {
    data: [],
    status: `idle`,
    error: null,
  },
  reducers: {
    setCampaignsStats(state,action){
      state.data = action.payload;
    },
    setCampaignsStatsSlice(state,action){
      return action.payload;
    },
  },
});

export default slice.reducer;
export const { setCampaignsStats,setCampaignsStatsSlice } = slice.actions;
export const selectCampaignsStats = state => state[name].data;
export const selectCampaignsStatsStatus = state => state[name].status;
export const selectCampaignsStatsSlice = state => state[name];