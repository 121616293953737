import 'font-awesome/css/font-awesome.min.css';
import React,{ lazy,Suspense } from 'react';
import 'react-dates/lib/css/_datepicker.css';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import Spinner from './components/spinner/spinner';
import './index.scss';
import store from './redux/store';
import ReactModal from 'react-modal';
// import registerServiceWorker from "./registerServiceWorker"

const LazyApp = lazy(() => import(`./app/app`));

ReactModal.setAppElement(`#root`);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner/>}>
      <LazyApp/>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick/>
    </Suspense>
  </Provider>,
  document.getElementById(`root`),
);
// registerServiceWorker()