import React from 'react';
import styled from 'styled-components';
import { useEndpoint } from '../../hooks/useEndpoint';
import Link from '../link';
import Status from '../status';

const AdPreview = props => {
  let { ad_id,id,ad } = props;
  if(!ad_id) ad_id = id;
  const endpoint = ad_id ? `/ads/${ad_id}` : null;
  const { status,data = {} } = useEndpoint(endpoint);
  let { image_url,headline,brand_name } = ad || data || {};

  return (
    <Container>
      <Status status={status}>
        <Link to={`/ads/${ad_id}`}>
          <Column>
            {image_url && <Img
              src={image_url}
              alt="ad preview"/>}
            <Headline>{headline}</Headline>
            <Brand>{brand_name}</Brand>
          </Column>
        </Link>
      </Status>
    </Container>
  );
};

export default AdPreview;

const Container = styled.div`
  display:flex;
  justify-content:center;
`;

const Column = styled.div`
  display:flex;
  flex-direction:column;
  padding-left:10px;
  width:100%;
  max-width:250px;
  justify-content:space-between;
  align-items:center;
  margin-bottom:30px;
  text-align:center;
`;
const Img = styled.img`
  height:150px;
  width:200px;
  object-fit: contain;
`;
const Headline = styled.div`
  white-space:normal;
  font-weight:600;
`;
const Brand = styled.div`
  color:gray;
`;
