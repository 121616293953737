import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';


const SpinnerComponent = ({ size = 60,color,message }) => {
  return (
    <Container>
      <ClipLoader
        className="clip-loader"
        sizeUnit={`px`}
        size={size}
        color={color || `#FF586B`}
        loading={true}/>
      {message &&
      <Message>{message}</Message>
      }
    </Container>
  );
};

export default SpinnerComponent;

const Container = styled.div`
  /* margin:20px; */
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  height:100%;
`;
const Message = styled.div`
  color: #FF586B;
`;