import { createAsyncThunk,createSlice,createSelector } from '@reduxjs/toolkit';
import config from '../config';
import axios from 'axios';
import { selectJwt,selectUserId } from './auth.slice';
import { selectView } from './options.slice';
import { selectAdTexts } from './adTexts.slice';
import { selectAdMedia } from './adMedia.slice';
import { selectAdUrls } from './adUrls.slice';
import { randBetween } from '../utility/randBetween';
import { selectStartDate,selectEndDate } from './url.slice';
import { datesBetweenDates } from '../utility/datesBetweenDates';

const name = `adMetrics`;
const { SCHEME,HOST } = config;

export const fetchData = createAsyncThunk(`${name}/fetchData`,async(_,{ rejectWithValue,getState }) => {
  const state = getState();
  try{
    const response = await axios({
      method: `get`,
      url: `${SCHEME}://${HOST}/${name}`,
      headers: { 'Authorization': `Bearer ${selectJwt(state)}` },
    });
    return response.data.data || [];
  }catch(err){
    return rejectWithValue({ msg: err.response.data.message });
  }
},{
  condition: (_,{ getState }) => {
    const state = getState();
    return !!selectUserId(state)
    && state.url.parsed
    && selectView(state) === `advertiser`;
  },
});

export const createAdMetric = createAsyncThunk(`${name}/create`,async(action,{ getState,dispatch }) => {
  dispatch(add(action));
});

export const updateAdMetric = createAsyncThunk(`${name}/update`,async(action,{ getState,dispatch }) => {
  dispatch(update(action));
});

export const deleteAdMetric = createAsyncThunk(`${name}/delete`,async(action,{ getState,dispatch }) => {
  dispatch(remove(action));
});


const initialState = {
  data: [
    {

      id: 141321,
      ad_id: 123,
      clicks: 124,
      impressions: 2134,
      views: 1423,
      cost: 14.12,
      conversions: 12,
    },
    {
      id: 141324,
      ad_id: 123,
      clicks: 124,
      impressions: 2134,
      views: 1423,
      cost: 14.12,
      conversions: 12,
    },
  ],
  status: `idle`,
  error: null,
};
const slice = createSlice({
  name,
  initialState,
  reducers: {
    add(state,action){
      const newId = Math.max(...state.data.map(i => i.id).map(Number)) + 1;
      state.data.push({ id: newId,...action.payload });
    },
    update(state,action){
      const { id,...updates } = { ...action.payload };
      state.data.forEach((item,index) => {
        if(item.id === id) state.data[index] = { ...item,...updates };
      });
    },
    remove(state,action){
      const output = [];
      state.data.forEach(item => {
        if(item.id !== action.payload)
          output.push(item);
      });
      state.data = output;
    },
  },
  extraReducers: {
    [fetchData.pending]: (state,action) => {
      state.status = `loading`;
      state.data = [];
    },
    [fetchData.fulfilled]: (state,action) => {
      state.status = `succeeded`;
      state.data = action.payload;
    },
    [fetchData.rejected]: (state,action) => {
      state.status = `failed`;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
export const { add,update,remove } = slice.actions;
export const selectAdMetrics = createSelector(
  [selectAdTexts,selectAdMedia,selectAdUrls,selectStartDate,selectEndDate],
  (adTexts,adMedia,adUrls,startDate,endDate) => {
    const output = [];
    const dates = datesBetweenDates(startDate,endDate);
    const campaigns = [759390,795884];
    const adGroups = [123,456];
    campaigns.forEach(campaign_id => {
      adGroups.forEach(adGroupId => {
        dates.forEach(date => {
          adTexts.forEach(adText => {
            adMedia.forEach(adMedia => {
              adUrls.forEach(adUrl => {
                const impressions = randBetween(1000,2000,true);
                const clicks = Math.floor(impressions * randBetween(0.25,0.3));
                output.push({
                  campaignId: campaign_id,
                  adGroupId,
                  ad_id: [adText.id,adMedia.id,adUrl.id].join(`-`),
                  impressions,
                  views: Math.floor(impressions * randBetween(0.5,0.6)),
                  clicks,
                  conversions: Math.floor(impressions * randBetween(0.1,0.15)),
                  cost: Number((clicks * randBetween(0.1,0.2)).toFixed(2)),
                  date,
                });
              });
            });
          });
        });
      });
    });
    return output;
  },
);