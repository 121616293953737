import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import config from '../config';
import { selectJwt } from './auth.slice';

const { SCHEME,HOST } = config;
const name = `signup`;

export const signup = createAsyncThunk(`signup/register`,async(payload,{ getState,dispatch,rejectWithValue }) => {
  if(process.env.REACT_APP_BRANDING === `netizen`)
    if(payload.company_name && !payload.company_name.includes(`(Netizen)`))
      payload.company_name += ` (Netizen)`;
  const jwt = selectJwt(getState());
  try{
    const response = await axios.post(`${SCHEME}://${HOST}/account/signup`,
      payload,
      { headers: { 'Authorization': `Bearer ${jwt}` } });
    dispatch(setSignUp(response.data));
    dispatch(resetSignUp());
  }catch(err){
    toastr.error(err.response.data.message);
    return rejectWithValue({ msg: err.response.data.message });
  }
});

const initialState = {
  data: {},
  status: `idle`,
  error: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSignUp(state,action){
      state.data = action.payload;
    },
    resetSignUp(state,action){
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [signup.pending]: (state,action) => {
      state.status = `loading`;
    },
    [signup.fulfilled]: state => {
      state.status = `succeeded`;
    },
    [signup.rejected]: (state,action) => {
      state.status = `failed`;
      state.data = initialState.data;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
const { setSignUp,resetSignUp } = slice.actions;
export const selectSignup = state => state[name];