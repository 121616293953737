import React from 'react';
import Alert from '../components/spinner/alert';
import Spinner from '../components/spinner/spinner';

const Status = ({ status,children,message }) => {
  return (
    <div style={{ minHeight: 80 }}>
      {status === `loading` && <Spinner message={message}/>}
      {status === `failed` && <Alert/>}
      {status === `succeeded` && children}
      {status === `idle` && children}
      {!status && children}
    </div>
  );
};

export default Status;