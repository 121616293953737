import { createSlice } from '@reduxjs/toolkit';

const name = `statsByWidget`;

const initialState = {
  data: [],
  status: `idle`,
  error: null,
};
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setStatsByWidget(state,action){
      state.data = action.payload ;
    },
    resetStatsByWidgetSlice(state,action){
      state = initialState;
    },
  },
});

export default slice.reducer;
export const { setStatsByWidget,resetStatsByWidgetSlice } = slice.actions;
export const selectStatsByWidget = state => state.statsByWidget.data;
export const selectStatsByWidgetSlice = state => state.statsByWidget;
