import React from 'react';
import Toggle from '../toggle';
import { updateAd } from '../../redux/ads.slice';
import { useDispatch } from 'react-redux';
import { useApi } from '../../hooks/useApi';

const AdToggle = ad => {
  const dispatch = useDispatch();
  const [api] = useApi();

  const handleChange = async() => {
    const status = { inactive: `active`,active: `inactive` }[ad.status];
    const response = await api({
      method: `put`,
      endpoint: `/ads/${ad.id}`,
      data: { status },
    });
    dispatch(updateAd(response.data.data));
  };

  if(!ad)return null;
  return (
    <Toggle
      value={ad.status === `active`}
      onChange={handleChange}/>
  );
};

export default AdToggle;
