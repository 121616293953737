import { color1,color2,color3,color4 } from '../utility/colors';
import { useUser } from './useUser';

const useTheme = () => {
  const { user } = useUser();
  const { theme } = user;
  const mode = theme === `rc-darkmode` ? darkmode : lightmode;
  setTheme(mode);
  return mode;
};

export default useTheme;

export const setTheme = theme => {
  Object.keys(theme).forEach(variable => {
    document
      .documentElement
      .style
      .setProperty(variable,theme[variable]);
  });
  document.getElementsByTagName(`body`)[0]
    .style[`backgroundColor`] = theme[`--body-bg`];
};

const universal = {
  '--color1': color1,
  '--color2': color2,
  '--color3': color3,
  '--color4': color4,
  '--font-family': `Spartan`,
  '--multi-value-bg': `var(--color2)`,
  '--filter-value-bg': `var(--color1)`,
  '--button-color': `var(--color1)`,
};


export const darkmode = {
  ...universal,
  '--black-white': `black`,
  '--white-black': `white`,
  '--body-bg': `#1b1d21`,
  '--card-bg': `#232529`,
  '--text-color': `#fff`,
  '--subheading-color': `lightgray`,
  '--heading-color': `#fff`,
  '--form-control-bg': `#25272b`,
  '--border-color': `#535357`,
  '--highlight': `lightgray`,
};

export const lightmode = {
  ...universal,
  '--black-white': `white`,
  '--white-black': `kblac`,
  '--body-bg': `#f5f7fa`,
  '--card-bg': `#fff`,
  '--text-color': `black`,
  '--subheading-color': `gray`,
  '--heading-color': `black`,
  '--form-control-bg': `#fff`,
  '--border-color': `lightgray`,
  '--highlight': `lightgray`,
};
