import { selectUrlState } from '../redux/url.slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

const useLink = () => {
  const urlState = useSelector(selectUrlState);
  const history = useHistory();
  return to => {
    const queryString = qs.stringify(urlState);
    let newLocation = `${to}?` + queryString;
    history.push(newLocation);
  };
};

export default useLink;
