import React from 'react';
import Toggle from '../toggle';
import { useApi } from '../../hooks/useApi';
import { useDispatch } from 'react-redux';
import { useCampaignsTableCampaignsEndpoint } from '../../hooks/useCampaignsTableCampaigns';
import { getEndpoint } from '../../redux/endpoints.slice';

const CampaignToggle = campaign => {
  const dispatch = useDispatch();
  const [api] = useApi();
  const endpoint = useCampaignsTableCampaignsEndpoint();

  const handleChange = async e => {
    const status = { inactive: `active`,active: `inactive` }[campaign.status];
    await api({
      method: `put`,
      endpoint: `/campaigns/${campaign.id}`,
      data: { status },
    });
    dispatch(getEndpoint({ endpoint,reload: false }));
  };

  return (
    <Toggle
      value={campaign.status === `active`}
      onChange={handleChange}/>
  );
};

export default CampaignToggle;
