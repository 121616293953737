import React from 'react';
import styled from 'styled-components';
import Link from './link';

const CampaignName = ({ id,name }) => {
  return (
    <Link to={`/campaigns/${id}`}>
      <Container>
        {name}
      </Container>
    </Link>
  );
};

export default CampaignName;

const Container = styled.div`
  display:flex;
`;

