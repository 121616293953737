import styled from 'styled-components';
import React from 'react';
import ButtonGroup from '../buttonGroup';
import useLink from '../../hooks/useLink';
import SettingsIcon from '@material-ui/icons/Settings';

const AdActions = ad => {
  const link = useLink();

  const handleChange = change => {
    if(change === `edit`) link(`/ads/${ad.id}`);
  };

  const options = [
    {
      id: `edit`,
      label: ``,
      icon: <SettingsIcon/>,
      tooltip: `Edit`,
    },
  ];

  return (
    <Container>
      <ButtonGroup
        options={options}
        onChange={handleChange}/>
    </Container>
  );
};

export default AdActions;

const Container = styled.div`

`;