import styled from 'styled-components';
import React from 'react';
import ButtonGroup from '../buttonGroup';
import BlockIcon from '@material-ui/icons/Block';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useApi } from '../../hooks/useApi';
import { useDispatch,useSelector } from 'react-redux';
import { addToCampaignBlacklist,selectCampaignBlacklist,removeFromCampaignBlacklist } from '../../redux/campaignBlacklist.slice';
import useTheme from '../../hooks/useTheme';
import { useCampaignTargetingWidgetsEndpoint } from '../../hooks/useCampaignTargetingWidgets';
import { getEndpoint } from '../../redux/endpoints.slice';

const CampaignTargetingActions = ({ campaign_id,widget_id }) => {
  const theme = useTheme();
  const endpoint = useCampaignTargetingWidgetsEndpoint();
  const dispatch = useDispatch();
  const [api,status] = useApi();

  const campaignBlacklist = useSelector(selectCampaignBlacklist);
  const campaignBlacklistIds = campaignBlacklist.map(i => i.widget_id);

  const handleChange = e => {
    if(e === `blacklist`) blacklist();
    if(e === `reset`) reset();
  };

  const blacklist = async() => {
    if(campaignBlacklistIds.includes(widget_id))
      dispatch(removeFromCampaignBlacklist({ list: [widget_id],campaign_id }));
    else
      dispatch(addToCampaignBlacklist({ list: [widget_id],campaign_id }));
  };

  const reset = async() => {
    await api({
      method: `delete`,
      endpoint: `/campaigns/${campaign_id}/widgets/${widget_id}`,
    });
    dispatch(getEndpoint(endpoint));
  };

  const options = [
    {
      id: `blacklist`,
      label: ``,
      icon: <BlockIcon/>,
      tooltip: campaignBlacklistIds.includes(widget_id) ? `Remove from Blacklist` : `Add to Blacklist`,
      color: campaignBlacklistIds.includes(widget_id) ? theme[`--button-color`] : ``,
    },
    {
      id: `reset`,
      label: ``,
      icon: <RotateLeftIcon/>,
      tooltip: `Reset Bid`,
    },
  ];

  return (
    <Container>
      <ButtonGroup
        status={status}
        options={options}
        onChange={handleChange}/>
    </Container>
  );
};

export default CampaignTargetingActions;

const Container = styled.div`

`;