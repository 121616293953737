import variables from '../assets/scss/override.scss';

const netizen = {
  color1: `#097584`,
  color2: `#FFC101`,
  color3: `#3A1025`,
  color4: `#FFC101`,
};

const revcontent = {
  color1: `#0fb150`,
  color2: `#5bba47`,
  color3: `#95c93d`,
  color4: `#81b975`,
};

export const { color1 } = { netizen,revcontent }[process.env.REACT_APP_BRANDING];
export const { color2 } = { netizen,revcontent }[process.env.REACT_APP_BRANDING];
export const { color3 } = { netizen,revcontent }[process.env.REACT_APP_BRANDING];
export const { color4 } = { netizen,revcontent }[process.env.REACT_APP_BRANDING];

export const colors = [
  color1,
  color3,
  color2,
  variables.gray,
  variables.black1,
  variables.black2,
  variables.cardBg,
];


export const { black1,black2,gray,warning,cardBg } = variables;

export const darkmodeColors = [
  color1,
  variables.lightgray,
  color2,
  variables.gray,
  color3,
  variables.black1,
  variables.cardBg,
];