import React from 'react';
import styled from 'styled-components';
import { InputGroup as RSInputGroup,InputGroupAddon,Input } from 'reactstrap';

const InputGroup = ({
  prepend,
  placeholder,
  value,
  onChange,
  append,
  onSubmit,
  buttonText = `update`,
  color,
  type = `text`,
  ...rest
}) => {

  const handleChange = e => {
    let { value } = e.target;
    onChange(value);
  };

  const handleKeyUp = e => {
    if(e.key === `Enter` && onSubmit) onSubmit();
  };

  return (
    <Container color={color}>
      <RSInputGroup size="md">
        {prepend && <InputGroupAddon addonType="prepend">{prepend}</InputGroupAddon>}
        <Input
          onKeyUp={handleKeyUp}
          placeholder={placeholder}
          type={type}
          value={value || ``}
          onChange={handleChange}
          {...rest}/>
        {append && <InputGroupAddon addonType="append">{append}</InputGroupAddon>}
        {onSubmit && <InputGroupAddon onClick={onSubmit} addonType="append" style={{ cursor: `pointer` }}>{buttonText}</InputGroupAddon>}
      </RSInputGroup>
    </Container>
  );
};

export default InputGroup;

const Container = styled.div`
  display:flex;
  flex-grow:1;
  height:38px !important;

  input[type=text]{
    min-width:90px;
  }
  .input-group-text{
    background-color:var(--highlight);
    height:38px !important;
  }
  .input-group{
    margin-bottom:0;
    flex-wrap:nowrap;
  }
  .input-group-append>.input-group-text{
    background-color:${p => p.color};
    border-color:${p => p.color};
    color:${p => p.color ? `white` : ``};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;