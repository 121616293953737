import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { createSelector } from 'reselect';
import { calcPublisherMetrics } from '../utility/calcMetrics';

const name = `statsByDate`;

const initialState = {
  data: [],
  status: `idle`,
  error: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setStatsByDate(state,action){
      state.data = action.payload;
    },
    resetStatsByDateSlice(state,action){
      state = initialState;
    },
  },
});

export default slice.reducer;
export const { setStatsByDate,resetStatsByDateSlice } = slice.actions;
export const selectStatsByDate = state => state.statsByDate.data;
export const selectStatsByDateStatus = state => state.statsByDate.status;
export const selectStatsByDateSlice = state => state.statsByDate;

export const selectMetricsByDate = createSelector(
  [selectStatsByDate],
  data => {
    return calcPublisherMetrics(data)
      .map(d => {
        if(!d.date) return { ...d };
        return {
          ...d,
          date: d.date.slice(0,10),
        };
      })
      .sort((a,b) => moment(a.date) - moment(b.date));
  }
);