
import { useSelector } from 'react-redux';
import { useEndpoint } from './useEndpoint';
import qs from 'qs';

export const useCampaignsTableCampaignsEndpoint = () => {
  const {
    page,
    perPage,
    sortBy,
    sortAsc,
    campaignsFreetext,
    campaignsStatus,
    campaignsSystemStatus,
    campaignsBidType,
  } = useSelector(s => s.url);

  const queryString = {
    offset: page,
    limit: perPage,
    orderBy: sortBy,
    order: sortAsc ? `asc` : `desc`,
  };
  if(campaignsFreetext) queryString.freetext = campaignsFreetext;
  if(campaignsBidType) queryString.bid_type = campaignsBidType;
  if(campaignsStatus) queryString.status = campaignsStatus;
  if(campaignsSystemStatus) queryString.system_status = campaignsSystemStatus;
  return `/campaigns?${qs.stringify(queryString)}`;
};

export const useCampaignsTableCampaigns = () => {
  const endpoint = useCampaignsTableCampaignsEndpoint();
  const { data: campaigns = [],...rest } = useEndpoint(endpoint);
  return { ...rest,campaigns };
};