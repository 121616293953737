import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import config from '../config';
import { selectJwt,selectUserId } from './auth.slice';
import axios from 'axios';

const name = `blacklist`;
const { SCHEME,HOST } = config;

export const addToBlacklist = createAsyncThunk(`${name}/add`,async(list,{ rejectWithValue,getState,dispatch }) => {
  const state = getState();
  const jwt = selectJwt(state);
  try{
    const response = await axios({
      method: `post`,
      url: `${SCHEME}://${HOST}/blacklist`,
      headers: { 'Authorization': `Bearer ${jwt}` },
      data: list.map(id => Number(id)),
    });
    dispatch(setBlacklist([...selectBlacklist(state),...response.data.data]));
  }catch(err){
    return rejectWithValue({ msg: err.response.data.message });
  }
},{ condition: (_,{ getState }) => !!selectUserId(getState()) });

export const removeFromBlacklist = createAsyncThunk(`${name}/remove`,async(list,{ rejectWithValue,getState,dispatch }) => {
  const state = getState();
  const jwt = selectJwt(state);
  try{
    await axios({
      method: `patch`,
      url: `${SCHEME}://${HOST}/blacklist/delete`,
      headers: { 'Authorization': `Bearer ${jwt}` },
      data: list.map(Number),
    });
    dispatch(setBlacklist(selectBlacklist(state).filter(b => !list.includes(b.widget_id))));
  }catch(err){
    return rejectWithValue({ msg: err.response.data.message });
  }
},{ condition: (_,{ getState }) => !!selectUserId(getState()) });

const slice = createSlice({
  name,
  initialState: {
    data: [],
    status: `idle`,
    error: null,
  },
  reducers: {
    setBlacklist: (state,action) => {
      state.data = action.payload;
    },
  },
});

export default slice.reducer;
export const { setBlacklist } = slice.actions;
export const selectBlacklist = state => state[name].data;