import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetStatsByDateSlice } from './statsByDate.slice';
import { resetStatsByDimSlice } from './statsByDim.slice';
import { resetStatsByDomainSlice } from './statsByDomain.slice';
import { resetStatsByWidgetSlice } from './statsByWidget.slice';
import { resetStatsSlice } from './stats.slice';


export const resetStore = createAsyncThunk(`reset_store`,async(_,{ getState,dispatch }) => {
  dispatch(resetStatsByDateSlice());
  dispatch(resetStatsByDomainSlice());
  dispatch(resetStatsByDimSlice());
  dispatch(resetStatsByWidgetSlice());
  dispatch(resetStatsSlice());
});