import { createSlice } from '@reduxjs/toolkit';

const name = `adsStats`;

const slice = createSlice({
  name,
  initialState: {
    data: [],
    status: `idle`,
    error: null,
  },
  reducers: {
    setAdsStats(state,action){
      state.data = action.payload;
    },
    setAdsStatsSlice(state,action){
      return action.payload;
    },
  },
});

export default slice.reducer;
export const { setAdsStats,setAdsStatsSlice } = slice.actions;
export const selectAdsStats = state => state[name].data;
export const selectAdsStatsStatus = state => state[name].status;